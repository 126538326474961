<template>
  <div class="ui personal-info">
    <header class="header">
      <h2>Account details</h2>
    </header>
    <div class="content align-form">
      <DynamicForm class="out-form" v-model="personalInfoForm" :submit-promise="submitPromise" @df-submit="submitPersonalInfo" />
    </div>
  </div>
</template>

<script>
import deepcopy from 'deepcopy'
import { mapGetters, mapActions } from 'vuex'
import { authGetters, userRoutine } from '@/store/modules/auth/routines'
import DynamicForm from '@/components/forms/DynamicForm'
import personalInfoForm from './personalInfo.form'
import { updateUser } from '@/api/user'
import { prefillFormData } from '@/utils/formHelpers'
import { toastDefaultOptions } from '../../../config/vue-toast'
import { convertToDateString } from '@/utils/utils'

export default {
  name: 'PersonalInfo',
  components: {
    DynamicForm
  },
  data() {
    return {
      personalInfoForm: deepcopy(personalInfoForm),
      submitPromise: null
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER
    })
  },
  methods: {
    ...mapActions({
      requestUser: userRoutine.TRIGGER
    }),
    updatePersonalInfoFormData() {
      return this.requestUser().then(res => {
        prefillFormData(this.personalInfoForm, res.data)
        return res.data
      })
    },
    submitPersonalInfo(formData) {
      const dateSerialized = convertToDateString(formData.data.doB)
      // return false
      const data = {
        ...formData.data,
        doB: dateSerialized,
        userId: this.user.userId
      }
      this.submitPromise = updateUser(data)
        .then(this.updatePersonalInfoFormData)
        .then(data => {
          this.$toasted.show('Account details :: Settings has been successfully saved..', { ...toastDefaultOptions, type: 'success', icon: 'check' })

          return data
        })
    }
  },
  created() {
    prefillFormData(this.personalInfoForm, this.user)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
.ui.personal-info {
  .header {
    margin: 20px 0;
    padding: 0 24px;
    font-weight: 400;
  }

  .content {
    padding: 0 24px;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .ui.personal-info {
    .header {
      padding: 0 10px;
    }
    .content {
      padding: 0 10px;
    }
  }
}
</style>
